.backgroundTopSlide {
  align-self: stretch;
  position: absolute;
  margin: 0 !important;
  top: -12px;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 250, 39, 0.2) 99.99%, transparent),
    rgba(55, 193, 255, 0.21);
  height: 896px;
  flex-shrink: 0;
  z-index: 0;
}
.interviewai {
  align-self: stretch;
  position: relative;
  font-weight: 800;
  font-size: 30px;
}
.aboutUs,
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.logo {
  width: 239px;
  flex-direction: column;
}
.aboutUs {
  font-weight: 800;
  width: 106px;
  height: 14px;
}
.aboutUs,
.u {
  position: relative;
}
.aboutUsParent,
.userBox {
  flex-shrink: 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.userBox {
  border-radius: var(--br-21xl);
  background-color: var(--color-lightgray-200);
  width: 41px;
  height: 41px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-2xs);
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-3xl);
  font-family: var(--font-inter);
}
.aboutUsParent {
  width: 185px;
  height: 52px;
  flex-direction: row;
  padding: 13px 0;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: 18px;
}
.headerGrouping {
  text-decoration: none;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) 0 4px;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: inherit;
  font-family: var(--font-open-sans);
}
.userBox1 {
  background-color: var(--color-lightgray-200);
  width: 51px;
  height: 51px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.thisIsWhere {
  flex: 1;
  position: relative;
}
.userTextRow1 {
  width: 1371px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.userBox2,
.userTextFrame1,
.userTextRow1 {
  display: flex;
  align-items: center;
}
.userTextFrame1 {
  align-self: stretch;
  background-color: var(--color-mediumseagreen-200);
  flex-direction: row;
  padding: var(--padding-23xl) var(--padding-58xl) var(--padding-41xl)
    var(--padding-11xl);
  justify-content: space-between;
  z-index: 2;
}
.userBox2 {
  background-color: var(--color-lightgray-100);
  width: 51px;
  height: 51px;
  flex-shrink: 0;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-2xs);
  box-sizing: border-box;
  justify-content: center;
}
.userTextFrame2,
.userTextFrame3 {
  align-self: stretch;
  background-color: var(--color-mediumseagreen-300);
  display: flex;
  flex-direction: row;
  padding: var(--padding-23xl) var(--padding-58xl) var(--padding-41xl)
    var(--padding-11xl);
  align-items: center;
  justify-content: space-between;
  z-index: 3;
}
.userTextFrame3 {
  background-color: var(--color-mediumseagreen-200);
  z-index: 4;
}
.userTextRow13 {
  width: 1371px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  z-index: 0;
}
.startTyping {
  border: 0;
  background-color: transparent;
  font-family: var(--font-inter);
  font-size: var(--font-size-3xl);
  align-self: stretch;
  flex: 1;
  position: relative;
  color: var(--color-gray);
  text-align: left;
  display: flex;
  align-items: center;
}
.vectorIcon {
  position: relative;
  width: 24.37px;
  height: 21.89px;
  flex-shrink: 0;
}
.sendMsgButton,
.typingBox {
  display: flex;
  align-items: flex-end;
}
.sendMsgButton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-10xs) var(--padding-11xs);
  background-color: transparent;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
}
.typingBox {
  margin: 0 !important;
  position: absolute;
  top: 134px;
  left: 351px;
  border-radius: var(--br-xl);
  background-color: rgba(86, 189, 102, 0.1);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 776px;
  height: 46px;
  flex-shrink: 0;
  overflow-y: auto;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-3xs) var(--padding-4xs)
    var(--padding-base);
  justify-content: flex-start;
  gap: var(--gap-8xs);
  z-index: 1;
}
.interviewPageStartMbpro,
.userTextFrame4 {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  background-color: var(--color-mediumseagreen-200);
}
.userTextFrame4 {
  align-self: stretch;
  background-color: var(--color-mediumseagreen-300);
  flex-direction: row;
  padding: var(--padding-23xl) var(--padding-58xl) 103px var(--padding-11xl);
  justify-content: space-between;
  z-index: 5;
}
.interviewPageStartMbpro {
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-2xs) 0 0;
  box-sizing: border-box;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-black);
  font-family: var(--font-inter);
  min-height: 100%;
  min-width: 100%;
  background-color: var(--color-mediumseagreen-200)
}
@media screen and (max-width: 420px) {
  .userTextRow13 {
    flex-direction: row;
    gap: var(--gap-3xs);
    align-items: center;
    justify-content: flex-start;
  }
}
