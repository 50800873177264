@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-open-sans: "Open Sans";

  /* font sizes */
  --font-size-3xl: 22px;
  --font-size-17xl: 36px;
  --font-size-13xl: 32px;
  --font-size-base: 16px;
  --font-size-sm: 14px;

  /* Colors */
  --color-white: #fff;
  --color-mediumseagreen-100: rgba(86, 189, 102, 0.4);
  --color-mediumseagreen-200: rgba(86, 189, 102, 0.33);
  --color-mediumseagreen-300: rgba(86, 189, 102, 0.2);
  --color-black: #000;
  --color-gray: rgba(0, 0, 0, 0.5);
  --color-lightgray-100: #d5d5d5;
  --color-lightgray-200: #d4d4d4;

  /* Gaps */
  --gap-8xs: 5px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-2xs: 11px;
  --padding-23xl: 42px;
  --padding-58xl: 77px;
  --padding-11xl: 30px;
  --padding-4xs: 9px;
  --padding-3xs: 10px;
  --padding-base: 16px;
  --padding-10xs: 3px;
  --padding-11xs: 2px;
  --padding-xs: 12px;
  --padding-41xl: 60px;

  /* border radiuses */
  --br-xl: 20px;
  --br-21xl: 40px;
  --br-81xl: 100px;
}
