.backgroundTopSlide {
  align-self: stretch;
  position: absolute;
  margin: 0 !important;
  top: -45px;
  left: 1px;
  background: linear-gradient(180deg, rgba(0, 250, 39, 0.2) 99.99%, transparent),
    rgba(55, 193, 255, 0.21);
  height: 991px;
  flex-shrink: 0;
  z-index: 0;
}
.headerGrouping,
.interviewai {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}
.interviewai {
  position: relative;
  font-weight: 800;
  align-items: center;
  width: 239px;
  height: 41px;
}
.headerGrouping {
  align-self: stretch;
  height: 65px;
  flex-direction: column;
  padding: 0 15px;
  box-sizing: border-box;
  align-items: flex-start;
  z-index: 1;
  font-size: var(--font-size-17xl);
}
.aiPoweredConsultingIntervie {
  align-self: stretch;
  position: relative;
}
.homepageSubtitle,
.homepageTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homepageTitle {
  z-index: 2;
  font-size: 64px;
}
.homepageSubtitle {
  z-index: 3;
  font-size: var(--font-size-13xl);
}
.sayHelloThen {
  border: 0;
  background-color: transparent;
  font-family: var(--font-inter);
  font-size: var(--font-size-3xl);
  flex: 1;
  position: relative;
  color: var(--color-gray);
  text-align: left;
}
.vectorIcon {
  position: relative;
  width: 24.37px;
  height: 21.89px;
  flex-shrink: 0;
}
.sendMsgButton {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-10xs) var(--padding-11xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.frame2TypingWindow,
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.frame2TypingWindow {
  border-radius: var(--br-xl);
  background-color: var(--color-mediumseagreen-300);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 512px;
  height: 39px;
  overflow-y: auto;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-3xs) var(--padding-4xs)
    var(--padding-base);
  gap: var(--gap-8xs);
  z-index: 4;
}
.text {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-open-sans);
  color: var(--color-white);
  text-align: center;
  width: 144px;
  height: 25px;
}
.tryInterviewCoachIconButt {
  cursor: pointer;
  border: 0;
  padding: 8px 0;
  background-color: var(--color-black);
  position: absolute;
  height: 15.27%;
  width: 62.34%;
  top: 84.73%;
  right: 19.05%;
  bottom: 0;
  left: 18.61%;
  border-radius: var(--br-81xl);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.coachingForAll {
  position: absolute;
  top: 131px;
  left: 13px;
  display: inline-block;
  font-weight: 700;
  width: 205px;
  height: 43px;
}
.comparableCoaches {
  margin: 0;
}
.comparableCoachesChargeContainer {
  position: absolute;
  top: 164px;
  left: 0;
  font-size: var(--font-size-sm);
  font-weight: 600;
  white-space: pre-wrap;
  display: inline-block;
  width: 231px;
  height: 58px;
}
.coachIcon1 {
  position: absolute;
  top: 18px;
  left: 63px;
  width: 105px;
  height: 105px;
  object-fit: cover;
}
.coachingForAllFrame {
  position: relative;
  width: 231px;
  height: 276px;
  flex-shrink: 0;
  z-index: 0;
}
.tryInterviewButtonOptimize {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-black);
  position: absolute;
  height: 14.34%;
  width: 62.34%;
  top: 85.66%;
  right: 18.61%;
  bottom: 0;
  left: 19.05%;
  border-radius: var(--br-81xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aiTrainedSpecifically,
.optimizedForYour {
  position: absolute;
  top: 130px;
  left: 3px;
  display: inline-block;
  font-weight: 700;
  width: 225px;
  height: 43px;
}
.aiTrainedSpecifically {
  top: 163px;
  left: 0;
  font-size: var(--font-size-sm);
  font-weight: 600;
  width: 231px;
  height: 58px;
}
.wandIcon {
  position: absolute;
  top: 0;
  left: 46px;
  width: 140px;
  height: 140px;
  overflow: hidden;
}
.optimizedFrame {
  position: relative;
  width: 231px;
  height: 276px;
  flex-shrink: 0;
  z-index: 1;
}
.whyUseInterviewai {
  position: absolute;
  margin: 0 !important;
  top: 7px;
  left: 386.5px;
  font-size: 20px;
  text-decoration: underline;
  display: inline-block;
  font-weight: 700;
  width: 216px;
  height: 27px;
  flex-shrink: 0;
  z-index: 2;
}
.text2 {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-open-sans);
  color: var(--color-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 139px;
  height: 25px;
  flex-shrink: 0;
}
.tryInterviewButtonAnalytic {
  cursor: pointer;
  border: 0;
  padding: 7px 0;
  background-color: var(--color-black);
  position: absolute;
  height: 14.49%;
  width: 60%;
  top: 85.51%;
  right: 20%;
  bottom: 0;
  left: 20%;
  border-radius: var(--br-81xl);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.advancedAnalyticsOnDemand,
.understandAndTrack {
  position: absolute;
  top: 127px;
  left: 0;
  display: inline-block;
  font-weight: 700;
  width: 240px;
  height: 43px;
}
.understandAndTrack {
  top: 160px;
  left: 4px;
  font-size: var(--font-size-sm);
  font-weight: 600;
  width: 231px;
  height: 58px;
}
.analyticsIcon {
  position: absolute;
  top: 0;
  left: 50px;
  width: 140px;
  height: 140px;
  overflow: hidden;
}
.analyticsFrame {
  position: relative;
  width: 231px;
  height: 276px;
  flex-shrink: 0;
  z-index: 3;
}
.text3,
.tryInterviewButtonMoreTyp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text3 {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-open-sans);
  color: var(--color-white);
  text-align: center;
  width: 162px;
  height: 25px;
  flex-shrink: 0;
}
.tryInterviewButtonMoreTyp {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-black);
  position: absolute;
  height: 14.18%;
  width: 70.24%;
  top: 85.82%;
  right: 15.12%;
  bottom: 0;
  left: 14.63%;
  border-radius: var(--br-81xl);
  flex-direction: column;
}
.tryInterviewButtonMoreTyp:hover {
  background-color: gray;
}
.interviewsForIbContainer,
.moreInterviewTypesContainer {
  position: absolute;
  top: 133px;
  left: 0;
  display: inline-block;
  font-weight: 700;
  width: 205px;
  height: 43px;
}
.interviewsForIbContainer {
  top: 166px;
  left: 9px;
  font-size: var(--font-size-sm);
  font-weight: 600;
  width: 186px;
  height: 58px;
}
.calendarIcon {
  position: absolute;
  top: 0;
  left: 32px;
  width: 140px;
  height: 140px;
  overflow: hidden;
}
.moreTypesFrame {
  position: relative;
  width: 231px;
  height: 276px;
  flex-shrink: 0;
  z-index: 4;
}
.whyUseInterviewAiBoxFram {
  border-radius: var(--br-21xl);
  background-color: rgba(86, 189, 102, 0.44);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-3xs);
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}
.contactUs,
.downArrowIcon {
  position: relative;
  flex-shrink: 0;
}
.contactUs {
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 28px;
}
.downArrowIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-top: -9px;
}
.downArrow,
.startingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.downArrow {
  width: 102px;
  height: 43px;
  flex-shrink: 0;
  cursor: pointer;
  z-index: 6;
}
.startingScreen {
  align-self: stretch;
  position: relative;
  gap: 58px;
}
.haveQuestions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 267px;
  height: 44px;
}
.contactUs1,
.haveQuestions,
.sendMsgButton1 {
  position: relative;
  flex-shrink: 0;
}
.contactUs1 {
  font-size: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 356px;
  height: 44px;
}
.sendMsgButton1 {
  width: 28.37px;
  height: 27.89px;
  overflow: hidden;
}
.frame2TypingWindow1,
.frame2TypingWindow2 {
  border: 1px solid var(--color-black);
  background-color: var(--color-mediumseagreen-100);
  align-self: stretch;
  border-radius: var(--br-xl);
  box-sizing: border-box;
  flex-shrink: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-3xs) var(--padding-4xs)
    var(--padding-base);
}
.frame2TypingWindow1 {
  height: 75px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.frame2TypingWindow2 {
  font-family: var(--font-inter);
  font-size: var(--font-size-3xl);
  height: 286px;
  align-items: flex-end;
  justify-content: flex-start;
}
.aboutUsPage,
.homepageMbpro14 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.aboutUsPage {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 250, 39, 0.1) 99.99%, transparent),
    rgba(55, 193, 255, 0.1);
  padding: 61px 122px;
  align-items: center;
  gap: 100px;
  font-size: var(--font-size-13xl);
}
.homepageMbpro14 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  overflow: hidden;
  padding: 29px 1px 0;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 39px;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-open-sans);
}
